import React from 'react'
import Layout from '../components/Layout'
import Legal from '../components/Legal/TextContent'
import MainGrid from '../components/styling/MainGrid'
import Breadcrumb from '../components/shared/Breadcrumb/Breadcrumb'

const breadcrumbData = [
  {
    title: 'Home',
    url: '/',
  },
  {
    title: 'Privacy Policy',
    url: '/privacy',
  },
]

const Privacy = () => {
  return (
    <Layout
      activeLink="/privacy"
      title="R&D Privacy Policy"
      description="R&D Privacy Policy"
    >
      <MainGrid noPaddingMobile noPaddingTablet>
        <Breadcrumb data={breadcrumbData} />
      </MainGrid>
      <Legal
        heading="Privacy policy"
        content={
          <div>
            <h6>Our Data Protection Principles</h6>
            <p>
              We know how important your privacy is, which is why we are always
              focused on protecting your personal data. We treat your
              confidentiality and data protection extremely seriously, with a
              constant commitment to our standards for information security.
            </p>
            <p>
              When you visit our site,{' '}
              <a href={process.env.BASE}>www.rda.co.uk</a> (the
              “Site”), or engage the services (the “Services”) provided by RDA, your data will be treated and handled in accordance with this Privacy Policy (the “Policy”).
            </p>
            <div className="line" />
            <h6>1. Purpose of the Policy</h6>
            <p>
              This Policy sets out the principles and procedures we have in
              place around the personal information and the data (separately and
              together, the “Information”) we take from you or which we receive
              about you from third parties. It sets out how we protect against
              potential breaches of confidentiality and how we protect the
              Information we hold and the systems and facilities we use from
              damage, loss or misuse.
            </p>
            <p>
              The Policy intends to help you understand how we gather, use,
              store, transfer and (ultimately) delete the Information we hold.
              It also addresses the purposes we keep your personal information
              for and how long it is kept for. Finally, the Policy sets out how
              we comply with our data-protection obligations and seek to protect
              the Information.
            </p>
            <p>
              Please take a moment to read and understand this Policy so that
              you can make informed decisions when you use our Services or our
              Site, but please also be aware that it only applies to the use of
              the Information we have obtained.
            </p>
            <div className="line" />
            <h6>2. Our contact details</h6>
            <p>
              Should you have anything you would like to clarify about this
              Policy, if you have any questions about it, or if you wish to
              apply your rights under the Policy, please contact us on email at{' '}
              <a href="mailto:info@rda.co.uk">
                info@rda.co.uk
              </a>{' '}
              or by calling us on{' '}
              <a href="tel:033 33 444 026">033 33 444 026</a>.
            </p>
            <div className="line" />
            <h6>3. Who we are</h6>
            <p>
              RDA (the “Company”) is a Research & Development
              consultancy that specialises in undertaking claims under the HMRC
              R&D Tax Credits Scheme. RDA UK Ltd is the company
              responsible for controlling your Information processed via the
              Site.{' '}
            </p>
            <div className="line" />
            <h6>4. The data we collect</h6>
            <p>
              The Information covered by this Policy includes all written,
              spoken and electronic Information we obtain, hold, use or transmit
              – in whatever form that may be. This includes the Information we
              hold on computer systems, hand-held devices, phones, paper records
              and conveyed through the spoken word.
            </p>
            <p>
              We are committed to being concise, clear and transparent about how
              we obtain and use the Information and how (and when) we delete it
              once it is no longer required. We will review and update this
              Policy in accordance with our statutory and regulatory
              data-protection obligations.
            </p>
            <div className="line" />
            <h6>5. Our people</h6>
            <p>
              All Staff (“Staff”), which means for this Policy all employees,
              temporary and agency workers, other contractors, interns and
              volunteers engaged by RDA from time to time, must be
              familiar with the Policy. All Staff who have access to the
              Information for necessary data-processing tasks are contractually
              obliged to respect the confidentiality of the Information and to
              abide by this Policy. Staff commit to treating the Information as
              commercially valuable and to protecting it from loss, theft,
              misuse or inappropriate access or disclosure.{' '}
            </p>
            <p>
              These contractual commitments also apply to outside contractors
              such as our IT Support providers. In addition, the Company
              conducts the appropriate due diligence to satisfy itself that
              outside contractors’ systems and processes meet the data-security
              requirements of this Policy.
            </p>
            <p>
              The Managing Director is ultimately responsible for compliance
              with this Policy within the Company.
            </p>
            <div className="line" />
            <h6>Collecting and processing the Information</h6>
            <p>
              Our data-processing tasks relate to how we use or do anything with
              the Information. This includes obtaining, recording, organising
              and storing, amending, retrieving, disclosing and/or destroying
              the information we hold about you.
            </p>
            <p>
              We will only ever process your Information in a manner that is
              lawful, fair and transparent and we will collect it and process it
              only for specified and legitimate purposes.{' '}
            </p>
            <p>
              When we collect your Information, it is principally so that we
              can:
            </p>
            <p>
              a. respond to the requests you make on our Site or about our
              Services <br />
              b. check your identity <br />
              c. carry out our Services <br />
              d. develop, improve and market our Services <br />
              e. look into disputes <br />
              f. comply with judicial processes or regulatory requirements{' '}
              <br />
              g. protect other users of the Site or our Services <br />
              h. carry out recruitment
            </p>
            <p>
              Our Privacy Policy for Recruitment can be found{' '}
              <a href="/">here</a>.
            </p>
            <p>
              We will only process the amount of Information that is relevant
              and necessary for the purposes at hand. We will keep it accurate
              and up to date and will take reasonable steps to ensure any
              inaccurate Information is quickly deleted or corrected. If the
              Information we hold can lead to you being identified, we will keep
              it only for as long as is necessary for it to be processed for the
              required purpose.{' '}
            </p>
            <p>
              We will ensure the Information is securely held. We will do this
              by taking the appropriate technical and organisational measures to
              protect it against unauthorised or unlawful processing and against
              accidental loss, destruction or damage. These measures may
              include:
            </p>
            <p>
              a. making sure that, where possible, the Information is
              pseudonymised or encrypted; <br />
              b. ensuring the ongoing confidentiality, integrity, availability
              and resilience of processing systems and services; <br />
              c. ensuring that, in the event of a physical or technical
              incident, availability and access to the Information can be
              restored in a timely manner and <br />
              d. a process for regularly testing, assessing and evaluating how
              effective both the Company’s technical and organisational
              procedures - and those of its contractors - are.
            </p>
            <br />
            <p>
              Before we process the Information for the first time, and then
              regularly while continuing to do so, we will review why we are
              undertaking the data-processing task and satisfy ourselves that
              the purpose is legitimate and lawful. We will document our
              decision on this point through a legitimate-interests assessment
              (“LIA”) to demonstrate our compliance with the Policy, keeping the
              LIA under review and repeating it where circumstances change.
            </p>
            <p>
              If the LIA identifies a significant privacy impact or that the
              processing task is likely to result in a high risk to
              data-protection rights (e.g. where the Company is planning to use
              a new form of technology), we will consider whether we also need
              to conduct a data-protection impact assessment (“DPIA”). A DPIA
              will assess whether the processing is necessary and proportionate
              in relation to its purpose, the risk to individuals and what
              measures can be introduced to address the risks and protect the
              Information further.
            </p>
            <div className="line" />
            <p>7. Your rights around the Information we hold</p>
            <p>
              You have the following rights in relation to the Information we
              hold about you:
            </p>
            <p>
              a.<i>Your right to know</i> <br />
              You have a right to be informed about how, why and on what basis
              the Information is processed and that is set out in this Policy.
              You also have a right to access the Information we hold. If you
              ask us, we will confirm that your Information is being processed
              and will provide it to you.
            </p>
            <p>
              b.<i>Your right to correction</i> <br />
              If you tell us the Information we hold for you is inaccurate or
              incomplete, we will correct it.
            </p>
            <p>
              b.<i>Your right to deletion</i> <br />
              We will erase data that is no longer necessary for the purpose for
              which it was originally collected or processed, or if there are no
              overriding legitimate grounds for it to be processed. This is
              sometimes known as the right to be forgotten.
            </p>
            <p>
              b.<i>Your right to restrict processing</i> <br />
              You have a right to restrict the processing of Information where
              the accuracy of the Information is contested. This also applies
              where the processing is unlawful but where you do not want the
              data to be erased or where the Company no longer needs the
              Information but where you require the data to establish, exercise
              or defend a legal claim.
            </p>
            <p>
              If you wish to exercise any of the rights in this section, please
              contact the Company through the email address or telephone number
              detailed in section 2., above.
            </p>
            <div className="line" />
            <h6>8. The standards our contractors are held to</h6>
            <p>
              The Company sometimes uses external organisations to process
              personal information on its behalf for the Services it carries
              out. There are additional security arrangements in place,
              implemented in contracts with those organisations, to safeguard
              the security of the Information. Contracts with external
              organisations provide that they may act only on the written
              instructions of the Company and that those who access the
              Information have a duty at all times to maintain its
              confidentiality.
            </p>
            <p>
              The contracts also stipulate that the appropriate measures are
              taken to ensure the security of data processing and that
              subcontractors are only engaged with the prior consent of the
              Company and bound by a written contract. External organisations
              are also contractually required to help the Company meet their
              obligations in relation to the security of processing, the
              notification of data breaches and data-protection impact
              assessments, as well as to allow individuals to exercise their
              rights in relation to data protections.
            </p>
            <p>
              External organisations must contractually submit to audits and
              inspections, providing the Company with whatever information it
              needs to ensure they are meeting their data-protection
              obligations. They must also tell the Company immediately if they
              are asked to do something that might infringe data-protection law.
              When the contracts end, external organisations must delete or
              return all Information to the Company.
            </p>
            <div className="line" />
            <h6>9. Where the Information may go</h6>
            <p>
              Neither the Company nor its contractors will transfer the
              Information outside of the European Economic Area (EEA), which
              comprises the countries in the European Union and Iceland,
              Liechtenstein and Norway.
            </p>
            <div className="line" />
            <h6>10. How long we keep the Information for</h6>
            <p>
              The Information will be kept securely in accordance with this
              Policy and will not be retained for any longer than necessary. The
              length of time over which data will be retained will depend on the
              circumstances. Such circumstances might include the reasons why
              the Information was obtained and what Information is required in
              order for the Company to meet its obligations towards regulatory
              bodies or for professional-indemnity purposes.
            </p>
            <p>
              When the Information is no longer required, if it is held
              electronically it will be securely and permanently destroyed and
              any hard copies will be shredded, without further notice or
              liability.
            </p>
            <div className="line" />
            <h6>11. Data breaches</h6>
            <p>
              In the unlikely event of a data breach, the Company will make the
              required report to the Information Commissioner’s Office without
              undue delay and, where possible, within 72 hours of becoming aware
              of it if it is likely to result in a risk to the rights and
              freedoms of individuals. It will also notify the affected
              individuals if the data breach is likely to result in a high risk
              to their rights and freedoms and notification is required by law.
            </p>
          </div>
        }
      />
    </Layout>
  )
}

export default Privacy
